<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="提现类型">
        <el-select style="width: 200px" v-model="searchData.type" placeholder="请选择" size="mini" clearable>
          <el-option label="用户" :value="1"> </el-option>
          <el-option label="商户" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="持卡人姓名">
        <el-input style="width: 200px" clearable v-model="searchData.holderName" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="卡号">
        <el-input style="width: 200px" clearable v-model="searchData.cardNumber" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="银行名称">
        <el-input style="width: 200px" clearable v-model="searchData.bankName" size="mini" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="用户信息" align="left" min-width="240">
        <template slot-scope="{ row }">
          <div class="user-box" v-if="row.user">
            <div class="row">
              <div class="label">ID：</div>
              <div class="value">
                <el-link type="primary" @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })">{{ row.user.id }}</el-link>
              </div>
            </div>
            <div class="row">
              <div class="label">昵称：</div>
              <div class="value">{{ row.user.nickname }}</div>
            </div>
            <div class="row">
              <div class="label">联系方式：</div>
              <div class="value">{{ row.user.mobile }}</div>
            </div>
          </div>
            <div class="user-box" v-if="row.shop">
            <div class="row">
              <div class="label">ID：</div>
              <div class="value">
                <el-link type="primary" @click="$router.push({ name: 'TdShopList', query: { id: row.shop.id } })">{{ row.shop.id }}</el-link>
              </div>
            </div>
            <div class="row">
              <div class="label">商户名称：</div>
              <div class="value">{{ row.shop.name }}</div>
            </div>
            <div class="row">
              <div class="label">联系方式：</div>
              <div class="value">{{ row.shop.contact }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="typeDsc" label="提现类型" align="left" min-width="100">
      </el-table-column>
      <el-table-column prop="id" label="头像" min-width="100" align="left">
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 5px; margin-right: 10px"
            v-if="row.user"
            :src="row.user.face"
            :preview-src-list="[row.user.face]"
            fit="cover"
          >
            <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
              <el-image
            style="width: 60px; height: 60px; border-radius: 5px; margin-right: 10px"
            v-if="row.shop"
            :src="row.shop.cover"
            :preview-src-list="[row.shop.cover]"
            fit="cover"
          >
        <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
        </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="levelDsc" label="等级" align="left" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.user" type="primary" size="mini">{{ row.user.levelDsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="审核状态" min-width="100" align="left">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 20" type="primary" size="small">已通过</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">已拒绝</el-tag>
          <el-tag v-if="row.status == 0" type="warning" size="small">待审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="coin" label="提现信息" min-width="180" align="left">
        <template slot-scope="{ row }">
          <div class="withdraw-box">
            <div class="row">
              <div class="label">提现萝卜币：</div>
              <div class="value">
                {{ row.coin }}
              </div>
            </div>
            <div class="row">
              <div class="label">提现金额：</div>
              <div class="value">{{ row.money }}</div>
            </div>
            <div class="row">
              <div class="label">可得金额：</div>
              <div class="value">{{ row.userMoney }}</div>
            </div>
            <div class="row">
              <div class="label">平台收益金额：</div>
              <div class="value">{{ row.platformMoney }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="coin" label="银行卡信息" min-width="230" align="left">
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <div class="label">持卡人姓名：</div>
              <div class="value">
                {{ row.holderName }}
              </div>
            </div>
            <div class="row">
              <div class="label">银行名称：</div>
              <div class="value">{{ row.bankName }}</div>
            </div>
            <div class="row">
              <div class="label">卡号：</div>
              <div class="value">{{ row.cardNumber }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>
      <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin: 0 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="审核结果">
          <el-radio-group v-model="formData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="20">通过</el-radio>
            <el-radio :label="10">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核备注">
          <el-input style="width: 300px" v-model="formData.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="show_review = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitReview">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { reviewAPI, getListAPI } from './api'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      show_review: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        bankName: '',
        holderName: '',
        cardNumber: '',
        status: -1,
        type: null
      },
      formData: {
        id: '',
        status: 20, //0 - 待审核 10 审核失败 20 - 审核通过
        remark: ''
      },
      total: 0
    }
  },

  watch: {
    'searchData.status': {
      immediate: true,
      handler(newV, oldV) {
        this.getList()
      }
    }
  },
  activated() {},
  mounted() {},

  methods: {
    async getList() {
      const { page, pagesize, bankName, holderName, cardNumber, status, type } = this.searchData
      let params = { page, pagesize, bankName, holderName, cardNumber, type }
      if (status !== -1) {
        params.status = status
      }
      const res = await getListAPI(params)
      this.list = res.records
      this.total = res.totalRow
    },
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
    },
    handleReview({ id }) {
      this.formData.id = id
      this.show_review = true
    },
    submitReview() {
      reviewAPI(this.formData).then((res) => {
        this.$message.success('审核成功')
        this.show_review = false
        this.getList()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;
      transition: all 0.2s linear;
      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .user-box {
    .row {
      display: flex;
      .label {
        // min-width: 100px;
        text-align: left;
      }
      .value {
        text-align: left;
        // min-width: 150px;
      }
    }
  }
  .withdraw-box {
    .row {
      display: flex;
      .label {
        min-width: 100px;
        text-align: right;
      }
      .value {
        text-align: left;
        min-width: 100px;
      }
    }
  }
}
</style>
