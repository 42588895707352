import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/user/withdraw/page`,
    method: 'get',
    params
  })
}

export function reviewAPI(data) {
  return http({
    url: `/manage/user/withdraw/updateStatus`,
    method: 'post',
    data
  })
}

